// Utility function to get a cookie by name

export const prepareCustomerOrderData = (items, checkoutOptions, extras, priceBreakdown, couponCode, clientIP) => {
    console.log("((priceBreakdown.GST).toFixed(2)).toString(): ", ((priceBreakdown.GST).toFixed(2)).toString())
    // get client ip address and add it to the order data



    let data = {
        // Needs to be fetched from Woo to Tell what gateways are available. This might determine the form state
        // What payment methods do we have. 
        payment_method: "",
        payment_method_title: "",
        total_tax: Number((parseFloat(priceBreakdown.GST) + parseFloat(priceBreakdown.PST)).toFixed(2)),
        shipping_total: priceBreakdown.ShippingCost,
        total: priceBreakdown.SubTotal,
        set_paid: false,
        billing: {
            first_name: "",
            last_name: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "British Columbia",
            postcode: "",
            country: "Canada",
            email: "",
            phone: "",
            // order_notes: ""
        },
        shipping: {
            first_name: "",
            last_name: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "British Columbia",
            postcode: "",
            country: "Canada",
            // order_notes: ""
        },
        shipping_lines: [
            {
                method_id: 'flat_rate',
                method_title: 'Shipping',
                total: priceBreakdown.ShippingCost.toString(),
                taxes: [
                    {
                        id: 1,
                        total: (priceBreakdown.GST).toFixed(2),
                        subtotal: (priceBreakdown.GST).toFixed(2),
                    }
                ]
            }
        ],
        fee_lines: [
            {
                name: "GST (5%)",
                tax_status: "taxable",
                total: (priceBreakdown.GST).toFixed(2).toString(), // GST on Shipping
            },
            {
                name: "PST (7%)",
                tax_status: "taxable",
                total: "0", // PST on Products
            }
        ],
        items: [],
        customer_id: null,
        meta_data: [
            {
                "key": "pickup_location",
                "value": ""
            },
            // {
            //     "key": "order_notes",
            //     "value": ""
            // },
            {
                "key": "_customer_ip_address",
                "value": clientIP
            },
            {
                "key": "_customer_user_agent",
                "value": navigator.userAgent
            },
            {
                "key": "_session_data",
                "value": "sessionData"
            },
            // { "key": 'utm_source', "value": utmSource },
            // { "key": 'utm_medium', "value": utmMedium },
            // { "key": 'utm_campaign', "value": utmCampaign },
            // { key: 'order_origin', value: 'React Frontend' }
            // Add more metadata fields as needed
        ]
        // shipping_lines: shippingLines
    };
    // const items = ca
    // Append session metadata to existing meta_data

    console.log("WHATS UPPPPPPPdata")
    console.log(data)
    const savedOptions = checkoutOptions;
    console.log("checkoutOptions from prepareCheckoutData: ", checkoutOptions);
    const isGuest = JSON.parse(localStorage.getItem('isGuest'));
    console.log("isGuest: ", isGuest)
    //const pickupLocationAddress = JSON.parse(localStorage.getItem('pickupLocationAddress'))
    // console.log(savedOptions)
    data.items = items;

    if (couponCode.isValid) {
        data.coupon_code = couponCode.code
        data.coupon_lines = [
            {
                code: couponCode.code,
            }
        ]
    }

    //Update pick up location
    let pickupLocationAddress = checkoutOptions.pickupLocation.address;
    if (savedOptions.storePickup) {
        // data.meta_data.find(item => item.key === "pickup_location").value = (pickupLocationAddress.address_1 + ", " + pickupLocationAddress.city + ", " + pickupLocationAddress.country)
        data.meta_data.find(item => item.key === "pickup_location").value = pickupLocationAddress.city
        // data.meta_data.find(item => item.key === "order_notes").value = checkoutOptions.orderNotes
        data.customer_note = checkoutOptions.orderNotes

    } else if (savedOptions.storeDelivery) {
        data.meta_data.find(item => item.key === "pickup_location").value = ""
    }

    const billingInfo = JSON.parse(localStorage.getItem('billingInfo'))
    const shippingInfo = JSON.parse(localStorage.getItem('shippingInfo'))

    if (isGuest || isGuest == undefined || isGuest == null) {
        // Guest Info
        const guestInfo = JSON.parse(localStorage.getItem('checkout-state'))?.customerInfo
        // console.log(guestInfo)
        // const billingInfo = JSON.parse(localStorage.getItem('billingInfo'))
        // const shippingInfo = JSON.parse(localStorage.getItem('shippingInfo'))

        console.log(".........................Guest Login Checkout.........................")
        if (savedOptions.storePickup && savedOptions.storePayment) {
            console.log("Store Pickup and Pay in Store")
            console.log("extras(PickupLocation): ", extras)
            data.billing.first_name = guestInfo.firstName;
            data.billing.last_name = guestInfo.lastName;
            data.billing.email = guestInfo.email;
            data.billing.phone = guestInfo.phone;
            data.billing.address_1 = extras.pickupLocation.address.address_1;
            data.billing.city = extras.pickupLocation.address.city;
            data.billing.state = extras.pickupLocation.address.state;
            data.billing.postcode = extras.pickupLocation.address.postcode;
            data.billing.country = extras.pickupLocation.address.country;

            // data.shipping.first_name = guestInfo.firstName;
            // data.shipping.last_name = guestInfo.lastName;

            // data.shipping.address_1 = extras.pickupLocation.address.address_1;
            // data.shipping.city = extras.pickupLocation.address.city;
            // data.shipping.state = extras.pickupLocation.address.state;
            // data.shipping.postcode = extras.pickupLocation.address.postcode;
            // data.shipping.country = extras.pickupLocation.address.country;
            // data.billing.pickup_location = extras.pickupLocation.address
            // data.meta_data = [data.pickup_location]
            data.payment_method = "Store Payment"
            data.payment_method_title = "Store Payment"
            return data;

        } else if (savedOptions.onlinePayment && savedOptions.storeDelivery) {
            console.log("Store Delivery and Online Payment")

            data.billing = billingInfo;

            data.billing.first_name = guestInfo.firstName;
            data.billing.last_name = guestInfo.lastName;
            data.billing.email = guestInfo.email;
            data.billing.phone = guestInfo.phone;

            // data.shipping = shippingInfo;
            console.log("shippingInfo: ", shippingInfo)
            data.shipping.first_name = guestInfo.firstName;
            data.shipping.last_name = guestInfo.lastName;
            data.shipping.address_1 = shippingInfo.address_1;
            data.shipping.city = shippingInfo.city;
            data.shipping.state = shippingInfo.state;
            data.shipping.postcode = shippingInfo.postcode;
            data.shipping.country = shippingInfo.country;
            // data.meta_data.find(item => item.key === "order_notes").value = shippingInfo.order_notes
            data.customer_note = shippingInfo.order_notes
            data.payment_method = "Card"
            data.payment_method_title = "Online Payment"

            return data;

        } else if (savedOptions.storePickup && savedOptions.onlinePayment) {

            console.log("Store Pickup and Online Payment")

            data.billing = billingInfo;

            data.billing.first_name = guestInfo.firstName;
            data.billing.last_name = guestInfo.lastName;
            data.billing.email = guestInfo.email;
            data.billing.phone = guestInfo.phone;
            // data.shipping.first_name = guestInfo.firstName;
            // data.shipping.last_name = guestInfo.lastName;
            // data.shipping.phone = guestInfo.phone;

            // data.shipping.address_1 = extras.pickupLocation.address.address_1;
            // data.shipping.city = extras.pickupLocation.address.city;
            // data.shipping.state = extras.pickupLocation.address.state;
            // data.shipping.postcode = extras.pickupLocation.address.postcode;
            // data.shipping.country = extras.pickupLocation.address.country;

            // data.meta_data = [data.pickup_location]
            data.payment_method = "Card"
            data.payment_method_title = "Online Payment"

            return data;
        }
        else if (savedOptions.storePayment && savedOptions.storeDelivery) {
            console.log("Pay In Store and Store Delivery")

            // STRANGE OPTION
            data.billing.first_name = guestInfo.firstName;
            data.billing.last_name = guestInfo.lastName;
            data.billing.email = guestInfo.email;
            data.billing.phone = guestInfo.phone;
            data.billing.address_1 = extras.pickupLocation.address.address_1;
            data.billing.city = extras.pickupLocation.address.city;
            data.billing.state = extras.pickupLocation.address.state;
            data.billing.postcode = extras.pickupLocation.address.postcode;
            data.billing.country = extras.pickupLocation.address.country;

            // data.shipping = shippingInfo;
            // console.log("shippingInfo: ", shippingInfo)
            data.shipping.first_name = guestInfo.firstName;
            data.shipping.last_name = guestInfo.lastName;
            data.shipping.address_1 = shippingInfo.address_1;
            data.shipping.city = shippingInfo.city;
            data.shipping.state = shippingInfo.state;
            data.shipping.postcode = shippingInfo.postcode;
            data.shipping.country = shippingInfo.country;
            data.shipping = shippingInfo;
            // data.meta_data.find(item => item.key === "order_notes").value = shippingInfo.order_notes
            data.customer_note = shippingInfo.order_notes
            data.payment_method = "Pay In Store"
            data.payment_method_title = "Store Payment"

            return data;

        }
        else {
            console.log("SUMMIN WRONG")
            return {}
        }
    } else {
        console.log(".........................Customer Checkout.........................")

        // check if the user exists
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const guestInfo = JSON.parse(localStorage.getItem('checkout-state'))?.customerInfo
        data.customer_id = userDetails.id;

        const savedUserBillingInfo = JSON.parse(localStorage.getItem('userDetails')).billing
        const savedUserShippingInfo = JSON.parse(localStorage.getItem('userDetails')).shipping

        console.log("Preparing Customer order information......")

        if (savedOptions.storePickup && savedOptions.storePayment) {
            console.log("Store Pickup and Pay in Store")

            data.shipping.first_name = userDetails.first_name;
            data.shipping.last_name = userDetails.last_name;
            // data.shipping.address_1 = pickupLocationAddress.address.address_1;
            // data.shipping.city = pickupLocationAddress.city;
            // data.shipping.state = pickupLocationAddress.address.state;
            // data.shipping.postcode = pickupLocationAddress.address.postcode;
            // data.shipping.country = pickupLocationAddress.address.country;
            data.billing.first_name = userDetails.first_name;
            data.billing.last_name = userDetails.last_name;

            // data.billing = userDetails.billing;
            data.billing.email = userDetails.email;
            data.billing.phone = guestInfo.phone;
            data.billing.address_1 = userDetails.billing.address_1 || extras.pickupLocation.address.address_1;
            data.billing.city = userDetails.billing.city || extras.pickupLocation.address.city;
            data.billing.state = userDetails.billing.state || extras.pickupLocation.address.state;
            data.billing.postcode = userDetails.billing.postcode || extras.pickupLocation.address.postcode;
            data.billing.country = userDetails.billing.country || extras.pickupLocation.address.country;

            // data.meta_data = [data.pickup_location]
            data.payment_method = "Store Payment"
            data.payment_method_title = "Store Payment"

            return data;

        } else if (savedOptions.onlinePayment && savedOptions.storeDelivery) {
            console.log("Store Delivery and Online Payment")

            data.billing = JSON.parse(localStorage.getItem('billingInfo'))
            data.shipping = JSON.parse(localStorage.getItem('shippingInfo'))
            // data.shipping = shippingInfo;
            data.shipping.first_name = userDetails.first_name;
            data.shipping.last_name = userDetails.last_name;
            // data.meta_data.find(item => item.key === "order_notes").value = shippingInfo.order_notes
            data.customer_note = shippingInfo.order_notes
            data.payment_method = "Card"
            data.payment_method_title = "Online Payment"

            return data;
        } else if (savedOptions.storePickup && savedOptions.onlinePayment) {
            console.log("Store Pickup and Online Payment")
            data.billing = JSON.parse(localStorage.getItem('billingInfo'));

            data.billing.first_name = userDetails.first_name;
            data.billing.last_name = userDetails.last_name;
            data.billing.email = userDetails.email;

            // data.shipping = shippingInfo;
            // data.shipping.first_name = userDetails.first_name;
            // data.shipping.last_name = userDetails.last_name;
            // data.shipping.address_1 = pickupLocationAddress.address.address_1;
            // data.shipping.city = pickupLocationAddress.city;
            // data.shipping.state = pickupLocationAddress.address.state;
            // data.shipping.postcode = pickupLocationAddress.address.postcode;
            // data.shipping.country = pickupLocationAddress.address.country;

            // data.meta_data = [data.pickup_location]

            data.payment_method = "Card"
            data.payment_method_title = "Online Payment"

            return data;

        } else if (savedOptions.storePayment && savedOptions.storeDelivery) {
            console.log("Pay In Store and Store Delivery")
            // STRANGE OPTION
            // check
            data.billing = JSON.parse(localStorage.getItem('billingInfo'));;

            data.billing.first_name = userDetails.first_name;
            data.billing.last_name = userDetails.last_name;
            data.billing.email = userDetails.email;
            data.billing.phone = guestInfo.phone;
            data.billing.address_1 = userDetails.billing.address_1 || extras.pickupLocation.address.address_1;
            data.billing.city = userDetails.billing.city || extras.pickupLocation.address.city;
            data.billing.state = userDetails.billing.state || extras.pickupLocation.address.state;
            data.billing.postcode = userDetails.billing.postcode || extras.pickupLocation.address.postcode;
            data.billing.country = userDetails.billing.country || extras.pickupLocation.address.country;

            // data.shipping = shippingInfo;
            data.shipping.first_name = userDetails.first_name;
            data.shipping.last_name = userDetails.last_name;
            data.shipping = JSON.parse(localStorage.getItem('shippingInfo'))
            // data.meta_data.find(item => item.key === "order_notes").value = shippingInfo.order_notes
            data.customer_note = shippingInfo.order_notes
            data.payment_method = "Pay In Store"
            data.payment_method_title = "Store Payment"

            return data;

        }

        else {
            console.log("SUMMIN WRONG")
            return {};
        }
    }
};
