import React, { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { axiosProductsInstance } from '../dataFetch/axios.main';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import '../css/FavoriteProducts.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const FavoriteProducts = () => {
    const [favoriteData, setFavoriteData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(true);
        axiosProductsInstance.get('',{ params: {tag: 'Favorite', size: 50 } }).then(res => {
            setFavoriteData(res.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData])


    return (
        <div className="favorite-products">
            <div className="favorite-products-wrapper">
                <div className='favorite-products-title'>
                    <p className="favorite-titel">Top 10 Items In BC Now</p>
                    {/* <p className="favorite-subtitle">Our most popular seafood</p> */}
                </div>
                {loading ? 
                    <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    // loop={true}
                    centeredSlides={true}
                    centerInsufficientSlides={true}
                    centeredSlidesBounds={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    className="mySwiper"
                    >
                    {Array(10).fill(0).map((_, i) => (
                            <SwiperSlide key={i} className="product">
                                <div>
                                    <Skeleton circle={true} width={230} height={230} />
                                </div>
                                <div className="product-info">
                                    <Skeleton height={20} width={120} />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                    </Swiper>
                    :
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={20}
                        // loop={true}
                        centeredSlides={true}
                        centerInsufficientSlides={true}
                        centeredSlidesBounds={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        className="mySwiper"
                    >
                        {favoriteData.map((product, index) => (
                            <SwiperSlide key={index} className="product">
                                <div className='favorite-image-wrapper'>
                                    <div className='favorite-image' style={{ backgroundImage: 'url(' + product.thumbnail + ')' }}></div>
                                    <Link to={`/product/${product.slug}`} className="shop-now-fav"></Link>
                                </div>
                                <div className="product-info">
                                    <h3>{product.name}</h3>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }
            </div>
        </div>
    );
};

export default FavoriteProducts;
