import React, { useParams, useState, useEffect } from 'react';
import { faTrashCan, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import { useContext } from 'react';
import { useCart } from '../context/CartContext';
import useFacebookPixel from '../components/useFacebookPixel';
import { useLocationContext } from '../context/LocationContext';

import "../css/ProductList.css"


const ProductListItem = ({ product }) => {
    const navigate = useNavigate();

    // const { product, setProduct } = useState();
    const { trackAddToCart } = useFacebookPixel();
    const { cartItems, addItemToCart, removeItemFromCart, updateItemQuantity, getItemQuantity, isItemInCart, showSideBar, setShowSideBar } = useCart();
    const {selectedLocation, setSelectedLocation, showSelector, setShowSelector, isOpenedInProductList, setIsOpenedInProductList } = useLocationContext();

    // SideBar Implementation
    const [isSideBarVisible, setIsSideBarVisible] = useState(false);
    const alreadyRedirected = JSON.parse(localStorage.getItem('alreadyRedirected')) || false;

    const cartItem = cartItems.find(item => item.id === product.id);
    const initialQuantity = cartItem ? cartItem.quantity : 1;
    const [quantity, setQuantity] = useState(initialQuantity);

    const handleQuantityChange = (event) => {
        setQuantity(Number(event.target.value));
        updateItemQuantity(product, Number(event.target.value));
        setShowSideBar(true);
    };

    const handleNavigation = () => {

        if (!alreadyRedirected) {
            setIsOpenedInProductList(true);
            setShowSelector(true);
            return;
        } else {
            navigate(`/product/${product.slug}`);
        }
        
    };

    const handleAddToCart = (product) => {

        if (!alreadyRedirected) {
            setIsOpenedInProductList(true);
            setShowSelector(true);
            return;
        } else {
            addItemToCart(product);
            trackAddToCart(product.id, product.blog_price);
            setShowSideBar(true);
        }
    };

    useEffect(() => {
        setQuantity(getItemQuantity(product.id))
    }, [cartItems]);

    useEffect(() => {
    }, [quantity]);


    return (
        <div className="products-container-list">
            <div style={{position: 'relative'}}>
                <div className="product-image-wrapper">
                    <a onClick={handleNavigation} className={product.blog_stock_status === "outofstock" ? "product-image out-of-stock" : "product-image"} style={{ backgroundImage: `url(${product ? product.thumbnail : ""})` }}></a>
                    {alreadyRedirected ?
                        <>
                            {product.blog_on_sale && <span className="sale-label"></span>}
                        </>
                        :
                        <>
                            {product.on_sale && <span className="sale-label"></span>}
                        </>
                    }
                    {product.virtual && <span className="instore-label"></span>}
                    {product.tags.some(tag => tag.name === "Oneday") && <span className="oneday-label"></span>}
                    {product.tags.some(tag => tag.name === "Premium") && <span className="premium-label"></span>}
                    {product.tags.some(tag => tag.name === "Feature") && <span className="feature-label"></span>}
                </div>
            </div>
            <div className="product-info">
                <a onClick={handleNavigation} className="product-list-title">{product.name}</a>
                {/* <p>{product.description}</p> */}
                <div>
                    <span className="price">
                        ${alreadyRedirected ? product.blog_price : product.price}
                        <span className="price-uom">{product.unit}</span>
                    </span>
                    {alreadyRedirected ? 
                        <>
                            {product.blog_on_sale &&
                                <span className="regular-price">
                                    ${product.blog_regular_price}
                                    <span className="price-uom">{product.unit}</span>
                                </span>
                            }
                        </>
                        :
                        <>
                            {product.on_sale &&
                                <span className="regular-price">
                                    ${product.regular_price}
                                    <span className="price-uom">{product.unit}</span>
                                </span>
                            }
                        </>
                    }
                </div>
                {alreadyRedirected ?
                    <>
                        {(product.blog_stock_quantity && product.blog_stock_quantity <= 5) && <div className="low-stock">Only {product.blog_stock_quantity} left</div>}
                    </>
                    :
                    <>
                        {(product.stock_quantity && product.stock_quantity <= 5) && <div className="low-stock">Only {product.stock_quantity} left</div>}
                    </>
                }
            </div>
            <div className="product-list-purchase">
                {!isItemInCart(product.id) ? 
                    <>
                        {alreadyRedirected ?
                            <>
                                {product.blog_stock_status === 'outofstock' ?
                                    <button className="product-list-cart out-of-stock" disabled>OUT OF STOCK</button> :
                                    product.virtual === true ?
                                        <a onClick={handleNavigation} className="product-list-cart in-store-product">LEARN MORE</a> :
                                        <button className="product-list-cart" onClick={() => handleAddToCart(product)}>ADD TO CART</button>
                                }
                            </>
                            :
                            <>
                                {product.stock_status === 'outofstock' ?
                                    <button className="product-list-cart out-of-stock" disabled>OUT OF STOCK</button> :
                                    product.virtual === true ?
                                        <a onClick={handleNavigation} className="product-list-cart in-store-product">LEARN MORE</a> :
                                        <button className="product-list-cart" onClick={() => handleAddToCart(product)}>ADD TO CART</button>
                                }
                            </>
                        }
                    </>
                    : 
                    <div className="product-list-incart">
                        <div className="cart-quantity">
                            <div className="cart-quantity-button" onClick={() => removeItemFromCart(product.id)}><FontAwesomeIcon icon={faMinus} /></div>
                            {/* <input type="number" id="quantity" name="quantity" value={quantity} onChange={handleQuantityChange} min="1" /> */}
                            <div>{quantity}</div>
                            <div className="cart-quantity-button" onClick={() => addItemToCart(product)}> <FontAwesomeIcon icon={faPlus} /></div>
                        </div>
                        <div className='item-in-cart'>IN CART</div>
                    </div>
                }
            </div>
        </div >
    );
};

export default ProductListItem;