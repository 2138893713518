import React, { createContext, useState, useContext, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

// Create context for location/site selection
const LocationContext = createContext(null);

// Location Provider Component
export const LocationProvider = ({ children }) => {
  // Initialize from local storage or default
  const [selectedLocation, setSelectedLocation] = useState(() => {
    const savedLocation = localStorage.getItem('storeId');
    return savedLocation || '';
  });

  const [showSelector, setShowSelector] = useState(false);
  const [isOpenedInProductList, setIsOpenedInProductList] = useState(false);
  const isFirstRender = React.useRef(true);


  // Save to local storage whenever location changes
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    localStorage.setItem('storeId', selectedLocation);
    toast.success('Your selected store has been updated.', {
        position: 'bottom-center',
        duration: 4000,
        style: {
          background: '#e3fae8',
          border: '1px solid #4da052',
          borderRadius: '0',
          color: '#333',
        },
        iconTheme: {
          primary: '#4da052',
          secondary: '#FFF',
        }
    });
  }, [selectedLocation]);

  return (
    <LocationContext.Provider
      value={{
        selectedLocation,
        setSelectedLocation,
        showSelector,
        setShowSelector,
        isOpenedInProductList,
        setIsOpenedInProductList
      }}>
      {children}
    </LocationContext.Provider>
  );
};

// Custom hook for accessing location context
export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};
