import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { loadStripe } from "@stripe/stripe-js";
// const stripePromise = loadStripe('pk_live_510wXf6AtUiGVvMtLB5rrGzvYo8pJ70nThLCNSZGFOeS0en2eMxfURGqrNNhlKOVFfYXlMBSFr6jEQriTnp8xloPQ00wxYUYgBc');
import { Elements, CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { LocationProvider, useLocationContext } from './context/LocationContext';

ReactGA.initialize('G-77DDBCDCRE');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocationProvider>
      <App />
    </LocationProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//  <Elements stripe={stripePromise}>
     
//     </Elements>