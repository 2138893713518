import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import CheckoutSummaryItem from '../components/cart/CheckoutSummaryItem';
import validator from "validator";
import { axiosUserInstance, axiosUserDetailsInstance } from "../dataFetch/axios.main"
import MailchimpReSubscribe from '../components/MailChimpReSubscribe'
import ReactGA from 'react-ga4';
import useFacebookPixel from '../components/useFacebookPixel';
import ClipLoader from "react-spinners/ClipLoader";


import { useCart } from '../context/CartContext';
import { useCheckout } from '../context/CheckoutContext';

import CustomerInfo from './checkout/CustomerInfo'
import CustomerShipping from './checkout/CustomerShipping'
import CustomerBilling from './checkout/CustomerBilling'
import { useUser } from '../context/UserContext';
import "../css/Checkout.css";
import axios from 'axios';
import { prepareCustomerOrderData } from '../pages/checkout/prepareCheckoutData'
import { axiosOrderInstance, axiosMailChimpInstance } from '../mutations/checkout'
import { BrowserView, MobileView, isBrowser, isDesktop, isMobile, isTablet } from 'react-device-detect';

import {
    useMutation,
} from '@tanstack/react-query'

import Popup from 'reactjs-popup';
import CountdownBanner from '../components/CountdownBanner';
import { CardElement } from '@stripe/react-stripe-js';
import { set } from 'react-hook-form';

// import useUtmParams from '../hooks/useUtmParams';
// import useSessionData from '../hooks/cookieHook';

import { useUtm } from '../context/UtmContext';
import { refreshUtmParameters } from '../utils/utmUtils';
import { useLocationContext } from '../context/LocationContext';

const Checkout = () => {
    // Context
    const { utmParams } = useUtm();
    const { toggleCheckoutOption, checkoutOptions, checkoutOptionsNew, pickupLocation } = useCheckout();
    const { cartItems, getLineItemsForWoo, getLineItemsForCoupon, calculateTotalCartPrice, estimatedGST, estimatedPST, cartSubTotal, clearCart, shippingCost, setShippingCost, shippingCostCheckout, getTotalItems } = useCart();
    const { user, signOut, isLoggedIn, authenticate, getCustomerDetails, validateUser } = useUser();
    const { trackPurchase } = useFacebookPixel();
    const navigate = useNavigate();

    const [paymentSubTotal, setPaymentSubTotal] = useState(cartSubTotal);

    const [discountCode, setDiscountCode] = useState({
        code: "",
        isValid: false
    });
    const [discountAmount, setDiscountAmount] = useState(0.00);

    const [responseMessages, setResponseMessages] = useState({});

    const couponUsed = JSON.parse(localStorage.getItem('couponUsed')) || [];

    const {selectedLocation, setSelectedLocation } = useLocationContext();

    const pickupName = () => {
        switch (selectedLocation) {
            case 'ke':
                return 'Kelowna';
              case 'ne':
                return 'Nelson';
              case 'ka':
                return 'Kamloops';
              case 'gi':
                return 'Gibsons';
              case 'wv':
                return 'West Vancouver';
              case 'bu':
                return 'Burnaby';
              default:
                return 'Kelowna';
        }
    }

    // const sessionData = useSessionData();
    // console.log("sessionData: ", sessionData)


    const handleDataFromCountdown = (data) => {
        if (!couponUsed.includes(data)) {
            setDiscountCode(prev => ({
                ...prev,
                code: data
            }));
        } else {
            setDiscountCode(prev => ({
                ...prev,
                code: ''
            }));
        }
    }

    const validateCoupon = useMutation({
        mutationFn: (data) => {
            console.log("Applying Coupon")
            console.log("data in couponData", data)
            return axios.post('https://fm.access-point.xyz/wp-json/custom/v1/validate-coupon', data);
        },
        onSuccess: async (response) => {
            console.log("Coupon Applied");
            console.log(response);
            setResponseMessages(response.data);
            setDiscountAmount(response.data.discount);
            setDiscountCode(prev => ({
                ...prev,
                isValid: true
            }));
        },
        onError: async (error) => {
            console.log(error)
            setResponseMessages(error.response.data);
            setDiscountAmount(0.00);
            setDiscountCode(prev => ({
                ...prev,
                isValid: false
            }));
        }
    })
    useEffect(() => {
        setPaymentSubTotal((cartSubTotal - discountAmount) > 0 ? (cartSubTotal - discountAmount) : 0.00);
    }, [cartSubTotal, discountAmount])

    const applyCoupon = async (event) => {
        event.preventDefault();
        const items = getLineItemsForCoupon(cartItems);
        const user_email = JSON.parse(localStorage.getItem('checkout-state')).customerInfo.email;
        const couponData = {
            coupon_code: discountCode.code,
            cart_items: items,
            cart_total: cartSubTotal,
            user_email: user_email
        }
        console.log("couponData before apply coupon", couponData);
        validateCoupon.mutate(couponData);

    }
    const removeCoupon = () => {
        setDiscountAmount(0.00);
        setDiscountCode(prev => ({
            ...prev,
            code: "",
            isValid: false
        }));
        setResponseMessages({});
    }

    const [checkoutOpt, setCheckoutOpt] = useState(() => {
        const savedCheckOutOptions = JSON.parse(localStorage.getItem('checkout-state'))?.checkoutOptions;
        // Why is this console message printed twice.
        console.log("savedCheckOutOptions: ", savedCheckOutOptions);
        return savedCheckOutOptions ? savedCheckOutOptions : checkoutOptions
    });

    const getIP = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            console.log("ip.data.ip: ", response.data.ip);
            return response.data.ip;
        } catch (error) {
            console.error(error);
        }
    }

    const deviceType = () =>{
        const width = window.innerWidth;
        if (isMobile) {
          return 'mobile';
        } else if (isTablet) {
          return 'tablet';
        } else {
          return 'desktop';
        }
      }

    // const [pickupLocationAddress, setPickupLocationAddress] = useState(() => {
    //     const savedPickupLocationAddress = JSON.parse(localStorage.getItem('checkout-state'))?.pickupLocationAddress;

    //     return savedPickupLocationAddress ? savedPickupLocationAddress : {
    //         name: 'Loading Location....', // This can be the same as billing or different for pickup orders
    //         address: {
    //             address_1: 'Loading Location....',
    //             city: 'Loading Location....',
    //             state: 'Loading Location....',
    //             postalCode: 'Loading Location....',
    //             country: 'Loading Location....',
    //         },
    //         details: 'Loading Location....',
    //         enabled: 'Loading Location....'
    //     }
    // });
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState('');
    const eligibleShippingCities = ['west kelowna', 'penticton', 'summerland', 'peachland', 'lake country', 'vernon', 'salmon arm', 'armstrong', 'vancouver', 'west vancouver', 'north vancouver', 'east vancouver', 'south vancouver', 'burnaby', 'new westminster', 'port moody', 'ubc', 'university of british columbia', 'downtown', 'deep cove', 'horseshoe bay', 'port coquitlam', 'coquitlam'];

    const createOrder = useMutation({
        mutationFn: (data) => {
            console.log("Creating Order")
            console.log("data in createOrder", data)
            return axiosOrderInstance.post('feature-api/v1/order', data);
        },
        onSuccess: async (response) => {
            console.log("Created Order");
            console.log(response);
            // clearCart();
            if (checkoutOptionsNew?.storePayment) {
                navigate('success', { state: { orderData: response.data } });
            }
            // if (response.data.coupon_lines.length > 0) {
            //     if (!couponUsed.includes(response.data.coupon_lines[0].code)) {
            //         couponUsed.push(response.data.coupon_lines[0].code);
            //         localStorage.setItem('couponUsed', JSON.stringify(couponUsed));
            //     }
            // }
            // localStorage.setItem('paymentGateways', JSON.stringify(response.data));
            // setPaymentGateways(response.data)

            // online payment commented out temperatly
            //stripe payment 
            // else await stripePaymentCheckout(response.data);
            // ReactGA.event('purchase', {
            //     transaction_id: response.data.id,
            //     total: response.data.total,
            //     currency: response.data.currency,
            //     tax: response.data.total_tax,
            //     shipping: response.data.shipping,
            //     items: response.data.items.map(item => ({
            //       item_id: item.product_id,
            //       item_name: item.name,
            //       quantity: item.quantity,
            //       price: item.price
            //     }))
            //   });

            trackPurchase(response.data.id, response.data.total);
        },
        onError: async (error) => {
            console.log("Failed to create order")
            console.log(error)
        },
    });

    const updateCustomerBilling = useMutation({
        mutationFn: (data) => {
            console.log("Billing Payload");
            console.log(data);
            return axiosUserDetailsInstance.put(`v3/customers/${data.customer_id}`, {
                //   Billing data   

                first_name: data.billing.firstName,
                billing: {
                    "first_name": data.billing.first_name,
                    "last_name": data.billing.last_name,
                    "address_1": data.billing.address_1,
                    "address_2": data.billing.address_2,
                    "city": data.billing.city,
                    "postcode": data.billing.postcode,
                    "country": data.billing.country,
                    "state": data.billing.state,
                    "email": data.billing.email,
                    "phone": data.billing.phone
                }

            })
        },
        onSuccess: async (response) => {
            console.log("Updated Customer Billing")
            console.log(response);

        },
        onError: async (error) => {
            console.log("Error")
            console.log(error)
        }
    })

    const updateCustomerShipping = useMutation({
        mutationFn: (data) => {
            console.log("Shipping Payload: ", data);
            return axiosUserDetailsInstance.put(`v3/customers/${data.customer_id}`, {
                //   Billing data   

                first_name: data.shipping.firstName,
                shipping: {
                    "first_name": data.shipping.first_name,
                    "last_name": data.shipping.last_name,
                    "address_1": data.shipping.address_1,
                    "address_2": data.shipping.address_2,
                    "city": data.shipping.city,
                    "postcode": data.shipping.postcode,
                    "country": data.shipping.country,
                    "state": data.shipping.state,
                    "email": data.shipping.email,
                    "phone": data.shipping.phone
                }

            })
        },
        onSuccess: async (response) => {
            console.log("Updated Customer Shipping")
            console.log(response);

        },
        onError: async (error) => {
            console.log("Error")
            console.log(error)
        }
    })


    const placeOrder = async (event) => {

        event.preventDefault();
        const checkouStatus = JSON.parse(localStorage.getItem('checkout-state')).checkoutOptions.pickupLocation
        const shippingCity = JSON.parse(localStorage.getItem('shippingInfo')).city;
        const customerInfo = JSON.parse(localStorage.getItem('checkout-state')).customerInfo
        const isGuest = JSON.parse(localStorage.getItem('isGuest'));

        console.log("customerInfo: ", customerInfo)
        // console.log("checkoutOptionsNew: ", checkoutOptionsNew)
        if (isGuest == null) { //if checkout as a guest
            if (!customerInfo.firstName || !customerInfo.lastName || !customerInfo.email || !customerInfo.phone) {
                alert("Please fill out complete contact information or sign in.")
                return
            }
        }
        if (isGuest == false) { //if logged in
            if (!customerInfo.phone) {
                alert("Please fill out complete contact information.")
                return
            }
        }
        if (checkoutOptionsNew.storePickup && Object.keys(checkouStatus).length === 0) {
            alert("Please select a pickup location.")
            return
        }
        if (!checkoutOptionsNew.storePickup) {
            console.log("city: ", shippingCity.toLowerCase())
            if (!(await validateDeliveryForm("deliveryForm", "Please fill out all deliery information.")))
                return
            if (!eligibleShippingCities.includes(shippingCity.toLowerCase())) {
                alert("Sorry, the address is not eligible for delivery.")
                return
            }
        }
        // if(checkoutOptionsNew.onlinePayment){ // online payment commented out temperatly
        //     if( !(await validateDeliveryForm("paymentInfoForm", "Please fill out all deliery information.")) )
        //         return
        // }
        if (getTotalItems() == 0) {
            console.log("getTotalItems: ", getTotalItems)
            alert("Cart is empty!");
            return
        }
        // const gstTaxRateId = 
        const priceBreakdown = {
            "GST": Number(estimatedGST.toFixed(2)),
            "PST": Number(estimatedPST.toFixed(2)),
            "ShippingCost": Number(shippingCost.toFixed(2)),
            "SubTotal": Number(paymentSubTotal.toFixed(2))
        }
        console.log("priceBreakdown.SubTotal", priceBreakdown.SubTotal)
        const items = getLineItemsForWoo(cartItems);

        

        const clientIP = await getIP();
        const orderData = prepareCustomerOrderData(items, checkoutOptionsNew, { pickupLocation: checkouStatus }, priceBreakdown, discountCode, clientIP);

        console.log("orderData before place order", orderData);
        // if(orderData.billing.city != "" && isLoggedIn) updateCustomerBilling.mutate(orderData);
        // if (orderData.shipping.city != "" && isLoggedIn) updateCustomerShipping.mutate(orderData);

        if (isGuest == null && checkoutOptionsNew.optInPromotionalEmails) {
            registerGuestToMailChimp((customerInfo.firstName + ' ' + customerInfo.lastName), customerInfo.email);
        }

        const orderPayload = {
            ...orderData,
            blog_id: selectedLocation,
            pickup_name: pickupName(),
            meta_data: [
              ...orderData.meta_data,
              { key: '_wc_order_attribution_source', value: "organic" },
              { key: '_wc_order_attribution_source_type', value: "organic" },
           
              { key: '_wc_order_attribution_device_type', value: deviceType() },
              { key: '_wc_order_attribution_utm_source', value: utmParams.utm_source || "organic"},
              { key: '_wc_order_attribution_utm_medium', value: utmParams.utm_medium },
              { key: '_wc_order_attribution_utm_campaign', value: utmParams.utm_campaign },
              { key: '_wc_order_attribution_utm_term', value: utmParams.utm_term },
              { key: '_wc_order_attribution_utm_content', value: utmParams.utm_content },
            ],
          };
          console.log("orderPayload: ", orderPayload    )
          refreshUtmParameters();

        //   debugger;
        createOrder.mutate(orderPayload);

        // console.log("pickupLocation before placeOrder: ", pickupLocation)
        // console.log("checkoutOptionsNew: ", checkoutOptionsNew)
        // if(checkoutOptionsNew.storePickup) localStorage.
        // Set Cart Items

    };
    const validateDeliveryForm = async (formContainer, message) => {
        let container = document.getElementById(formContainer)
        var inputs = container.querySelectorAll("input[required]");

        var allFilled = true;
        inputs.forEach(function (input) {
            if (!input.value.trim()) {
                allFilled = false;
                input.classList.add('error');
            }
        });
        if (!allFilled) alert(message);
        return allFilled;
    }
    useEffect(() => {
        if (!checkoutOptionsNew.storePickup) {
            const shippingCost = checkoutOptionsNew.shippingCost;
            setShippingCost(shippingCost);
        } else {
            setShippingCost(0.00)
        }

    }, [checkoutOptionsNew])

    useEffect(() => {
        if (getTotalItems() == 0) navigate('/');
    }, [])

    const handleDiscountCode = (event) => {
        setDiscountCode(prev => ({
            ...prev,
            code: event.target.value
        }));
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleBlur = (event) => {
        // console.log("event: ", event)
        const input = event.target;

        if (input.value !== "") {
            input.classList.add("filled");

        } else {
            input.classList.remove("filled");
        }
    }
    const handleSignIn = async (event) => {
        event.preventDefault();
        // console.log("email: ", email)
        if (validator.isEmail(email)) authenticate.mutate({ email: email, password: password })
        else alert("Email format incorrect")

    };
    const stripePaymentCheckout = async (orderData) => {
        console.log(orderData);
        //const { token, error } = await stripe.createToken(CardElement);

        const data = {
            'email': orderData.billing.email,
            'name': orderData.billing.first_name + ' ' + orderData.billing.last_name,
            'address': orderData.billing.address_1,
            'city': orderData.billing.city,
            'state': orderData.billing.state,
            'zip': orderData.billing.postcode,
            'phone': orderData.billing.phone,
            'amount': Number(cartSubTotal.toFixed(2)),
            'order_id': orderData.id,
            'line_items': orderData.line_items,
            'pickup_location': orderData.billing.pickupLocation
            // payment_method: 12
        }

        axiosOrderInstance.post("/order/checkout", data)
            .then((res) => {
                console.log("responseData: ", res)
                if (res) {
                    window.location.href = res.data.url;
                } else {
                    console.log("Failed to process payment.");
                }
            });



        // axiosOrderInstance.post("/order/checkout", data);
    }

    const registerGuestToMailChimp = async (name, email) => {
        console.log("data before subscribe: ", name, email)
        const data = {
            'email': email,
            'name': name,
            'termsAccepted': true,
        }
        try {
            const res = await axiosMailChimpInstance.post('/mailchimp/register', data);
            console.log("response: ", res)
            if (res.status === 200) {
                console.log('Thank you for subscribing to our newsletter.');
            } else if (res.status === 400 && res.data.code === 'mailchimp_permanently_deleted') {
                // user has been permenantly deleted, have to use the MailChimp portal to re-subscribe
                window.location.href = 'http://eepurl.com/haDB35';
            } else {
                console.log('Failed to subscribe.');
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error: ", error, "error.response: ", error.response)

                if (error.response.status === 400 && error.response.data.code === 'mailchimp_permanently_deleted') {
                    window.location.href = 'http://eepurl.com/haDB35';
                } else {
                    console.log('Failed to subscribe: ' + (error.response.data.message || 'Unknown error'));
                }
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Error during registration:", error);
                console.log('An error occurred. Please try again later.');
            }
        }
    }

    useEffect(() => {
        console.log('User being accessed')
        if (isLoggedIn) {
            // fetch data from the DB
            console.log(user)
            getCustomerDetails.mutate({ id: user.ID })
            closeModal();
        }
    }, [user]);

    useEffect(() => {
        toggleCheckoutOption(checkoutOpt);
    }, [checkoutOpt])

    
    return (
        <div className="checkout-wrapper">

            <section className="checkout-section">
                <form className="checkout-form">

                    <div className='header-container'>
                        <h2>Contact</h2>
                        <div className='header-buttons'>
                            {user ? (
                                <>
                                    <Link to="/customer/account" className="customer-account-button">
                                        <FontAwesomeIcon icon={faUser} /> Hi, {(user.display_name)}
                                    </Link>
                                    <button className="customer-signout-button" onClick={signOut}>
                                        <FontAwesomeIcon icon={faSignOutAlt} />
                                    </button>
                                </>
                            ) : (
                                <button type="button" onClick={() => setOpen(o => !o)}>LOGIN</button>
                            )}
                        </div>
                    </div>

                    <Popup className="popup-container" open={open} closeOnDocumentClick onClose={closeModal}>
                        <div className="modal">
                            <div className="checkout-login-container">
                                <h2 className="checkout-login-title">Login</h2>
                                <form action="#" className="checkout-login-content-body">
                                    <div className="form-group">
                                        <input className="form-input" required={true} type="email" value={email} onBlur={handleBlur} onChange={handleEmailChange} />
                                        <label className="form-label" htmlFor="email">Email</label>
                                    </div>
                                    <div className="form-group">
                                        <input className="form-input" required={true} type="password" value={password} onBlur={handleBlur} onChange={handlePasswordChange} />
                                        <label className="form-label" htmlFor="password">Password</label>
                                    </div>
                                    <div className="form-group">
                                        <button className="checkout-login-form-button" onClick={handleSignIn} >{authenticate.isPending ? <ClipLoader size={10} color='#FFF' data-testid="loader" /> : 'SIGN IN'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Popup>

                    <CustomerInfo />
                    {/*<MailchimpReSubscribe />*/}
                    <h2>Delivery</h2>
                    <CustomerShipping />
                    <h2>Payment</h2>
                    <CustomerBilling />
                </form >
            </section >
            <div className="checkout-sidebar">
                <div className='cart-summary'>
                    <h2>Cart Summary</h2>
                    <div className='cart-items-summary'>
                        {cartItems.map(function (value, index) {
                            return <CheckoutSummaryItem key={index} item={value} />
                        })}
                    </div>
                    <div className="discount-code-info-form">
                        <div id="discount-code" className="form-group-checkout">
                            <label htmlFor="discountCode">Discount Code</label>
                            <div className='apply-discount-group'>
                                <input
                                    id="discountCode"
                                    type="text"
                                    className="form-control"
                                    value={discountCode.code}
                                    onChange={handleDiscountCode}
                                    placeholder="Discount Code"
                                    disabled={discountCode.isValid}
                                />
                                {discountCode.isValid ? 
                                    <button type="button" onClick={removeCoupon} >REMOVE</button> 
                                    :
                                    <button type="submit" onClick={applyCoupon} className={(discountCode.code !== "") && "active"}>APPLY</button>
                                }
                            </div>
                        </div>
                        {responseMessages && <div className="response-messages">{validateCoupon.isPending ? "Validating..." : responseMessages.message}</div>}

                        <CountdownBanner smSize={true} isShowButton={false} dataToParent={handleDataFromCountdown} />

                        <div className='checkout-summary-total-calculation-container'>

                            <div className="form-group-checkout">
                                <div className="apply-discount-group">
                                    <label className='checkout-summary-label' htmlFor="discountAmount">Item Total:</label>
                                    <div className='checkout-summary-value'> ${calculateTotalCartPrice()}</div>
                                </div>
                            </div>

                            <div className="form-group-checkout">
                                <div className="apply-discount-group">
                                    <label className="checkout-summary-label" htmlFor="discountAmount">Discount:</label>
                                    <div className='checkout-summary-value'>- ${discountAmount.toFixed(2)}</div>
                                </div>
                            </div>

                            <div className="form-group-checkout">
                                <div className="apply-discount-group">
                                    <label className='checkout-summary-label' htmlFor="discountAmount">Shipping:</label>
                                    <div className='checkout-summary-value'>{shippingCost ? "$" + shippingCost.toFixed(2) : "Free Shipping"}</div>
                                </div>
                            </div>
                            <div className="form-group-checkout">
                                <div className="apply-discount-group">
                                    <label className='checkout-summary-label' htmlFor="discountAmount">Estimated GST:</label>
                                    <div className='checkout-summary-value'>${estimatedGST.toFixed(2)}</div>
                                </div>
                            </div>
                            <div className="form-group-checkout">
                                <div className="apply-discount-group">
                                    <label className='checkout-summary-label' htmlFor="discountAmount">Estimated PST:</label>
                                    <div className='checkout-summary-value'>${estimatedPST.toFixed(2)}</div>
                                </div>
                            </div>
                            <div className="form-group-checkout-subtotal">
                                <div className="apply-discount-group">
                                    <label className="checkout-summary-label-subtotal" htmlFor="subTotal">Subtotal:</label>
                                    <div className='checkout-summary-value-subtotal'>${paymentSubTotal.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button disabled={createOrder.isPending ? true : false} onClick={placeOrder} className={createOrder.isPending ? "order-pending-button" : "order-now-button"} type="submit">{createOrder.isPending ? "LOADING.." : "PLACE AN ORDER"}</button>
                    <Link to={'/cart'} className="back-to-cart-button" >BACK TO CART</Link>
                </div>
            </div>

        </div >
    );
};

export default Checkout;

// <div className="form-group-checkout">
//     <div className="apply-discount-group">
//         <label className="checkout-summary-label" htmlFor="discountAmount">Discount Amount:</label>
//         <div className='checkout-summary-value'>${discountCodeInfo.discountAmount}</div>
//     </div>
// </div>